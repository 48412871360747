.classroom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.desks {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.desk-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.desk-pair {
  width: 140px;
  height: 120px;
  border: 1px solid #0019f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  overflow: hidden;
}

.student {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s;
  font-size: 18px;
  position: relative;
}

.student-icon {
  font-size: 40px;
  margin-bottom: 5px;
}

.student-name {
  text-align: center;
  word-break: break-word;
  margin-bottom: 5px;
}

.student-note {
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 2px 5px;
  border-radius: 3px;
  width: 100%;
  text-align: center;
}

.selecting {
  animation: colorChange 0.5s infinite;
}

.selected {
  background-color: rgba(9, 255, 0, 0.53);
}

@keyframes colorChange {
  0% { background-color: rgba(240, 240, 240, 0.3); }
  50% { background-color: rgba(0, 255, 21, 0.3); }
  100% { background-color: rgba(240, 240, 240, 0.3); }
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  margin-bottom: 10px;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.back-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
}

.back-button:hover {
  background-color: #d32f2f;
}

@keyframes shine {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.student-good {
  background: linear-gradient(45deg, rgba(243, 236, 120, 0.3), rgba(175, 66, 97, 0.3));
  background-size: 200% 200%;
  animation: shine 5s ease infinite;
  color: black;
  border: 2px solid #00c3ff;
}

.student-excellent {
  background: linear-gradient(45deg, rgba(255, 255, 0, 0.93), rgba(0, 255, 17, 0.93));
  background-size: 200% 200%;
  animation: shine 3s ease infinite;
  color: rgb(0, 0, 0);
  box-shadow: 0 0 30px rgba(0, 238, 255, 0.95);
  border: 2px solid #03fcca;
}

.student-excellent::before,
.student-good::before {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 20px;
  z-index: 1;
}

.student-excellent::before {
  content: '🏆';
}

.student-good::before {
  content: '👍';
}
