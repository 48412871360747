.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 16px;
  color: #33502c;
  margin: 0;
  padding: 20px;
  background-color: #f0f4f8;
  line-height: 1.6;
}


.container {
  max-width: 1000px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.12);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.answer-container {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.container_racine {
  max-width: 1000px;
  margin: 0 auto;
  background-color: rgba(173, 136, 39, 0.15);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
h1, h2 {
  text-align: center;
  color: #4eb6b9;
}
h6 {
  text-align: center;
  color: #7700ff;
  font-size: 22px;
}
.h7 {
  text-align: center;
  color: #e5ff00;
  font-size: 22px;
}
.level-buttons {
  text-align: center;
  margin-bottom: 30px;
}
.level-buttons button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #3498db;
  color: #fcfcfc;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.level-buttons button:hover {
  background-color: #2980b9;
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
#exercise-container {
  margin-top: 30px;
}
.exercise {
  background-color: #ecf0f1;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}


.exercise p {
  font-size: 28px;
  margin: 10px 0; /* Add this line to reduce vertical spacing between paragraphs */
}

.exercise:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
.exercise h3 {
  margin-top: 0;
  color: #2c3e50;
}
.answer {
  display: none;
  margin-top: 15px;
  background-color: #e8f5e9;
  padding: 15px;
  border-radius: 5px;
}


.show-answer-btn {
  margin-top: 15px;
  padding: 8px 16px;
  background-color: #2ecc71;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

}
.handwritten-score {
  font-family: 'Caveat', cursive;
  color: #ff0000;
  font-size: 2rem;
  transform: rotate(-5deg);
  display: inline-block;
  padding: 10px;
  background-color: #ffffa5;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border: 1px solid #e0e0e0;
}

.custom-card {
  margin-bottom: 32px;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.25);
}

.show-answer-btn:hover {
  background-color: #27ae60;
  transform: scale(1.05);
}
#num-exercises, #countdown-time, #exercise-type {
  width: 100px;
  font-size: 16px;
  margin: 5px;
  padding: 8px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  transition: all 0.3s ease;
}
#num-exercises:focus, #countdown-time:focus, #exercise-type:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}
.math-expression {
  font-size: 1.3em;
}
#countdown-container {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #34495e;
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all 0.3s ease;
}
#countdown {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}
#countdown.expired {
  color: #e74c3c;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
.exercise {
  animation: fadeIn 0.5s ease-in-out;
}