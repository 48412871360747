.class-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .button-container {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .class-button {
    padding: 15px 30px;
    font-size: 18px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .class-button:hover {
    background-color: #45a049;
  }
  